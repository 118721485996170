@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
/* Document
 * ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}
/* Sections
 * ========================================================================== */
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
 * ========================================================================== */
/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */
dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}
/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */
ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
/**
 * Add the correct display in IE.
 */
main {
  display: block;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/* Text-level semantics
 * ========================================================================== */
/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/* Embedded content
 * ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Forms
 * ========================================================================== */
/**
 * Remove the margin on controls in Safari.
 */
button,
input,
select {
  margin: 0;
}
/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */
button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}
/**
 * Correct the inability to style buttons in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * Show the overflow in Edge 18- and IE.
 */
input {
  overflow: visible;
}
/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}
/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none;
}
/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */
textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}
/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}
/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}
/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge 18- and IE.
 */
details {
  display: block;
}
/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
dialog:not([open]) {
  display: none;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE.
 */
template {
  display: none;
}
/* User interaction
 * ========================================================================== */
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
body {
    background-color: #003a71;
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/** Home */

.Home_wrapper__18s1D {
    text-align: center;
    color: white;
}

.Home_menu__p9nnd {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.Home_button__2QAVu {
    min-width: 256px;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.Home_versus__6yein {
    display: -webkit-flex;
    display: flex;
    width: 512px;
    margin: 16px;
    background-color: #B2D2E4;
    border-radius: 4px;
    color: #235c8d;
    padding: 16px;
}

.Home_versus-button__1ODlf {
    background-color: #235c8d;
    color: white;
    padding: 8px;
    border-radius: 4px;
    min-width: 100px;
}

.Home_versus-link__1VzwI {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    color: white;
    padding: 8px;
    -webkit-align-self: stretch;
            align-self: stretch;
}
.Setup_username-wrapper__1oXqE {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 800px;
    margin: auto;
}

.Setup_username-label__21Pcv {
    color: white;
    font-size: x-large;
    -webkit-align-self: flex-start;
            align-self: flex-start;
    padding: 0px 16px;
}

.Setup_username-select__3udF_ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: stretch;
            align-self: stretch;
}

.Setup_setup-form__287fC {
    display: -webkit-flex;
    display: flex;
    -webkit-align-self: center;
            align-self: center;
}

.Setup_shuffle-username__1gKNI {
    background-color: #235c8d;
    padding: 8px;
    margin: 16px 8px;
    border-radius: 4px;
    cursor: pointer;
    color: #b2d2e4;
    font-size: large;
}

.Setup_shuffle-username__1gKNI > svg {
    padding-left: 6px;
}

.Setup_submit__2p6Fo {
    background-color: #235c8d;
    padding: 8px;
    margin: 16px 8px;
    min-width: 64px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    color: #b2d2e4;
    font-size: large;
}

.Setup_dropdown-wrapper__2mBIt {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.Setup_dropdown-header__20beT {
    margin: 16px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Setup_dropdown-list__NNhsW {
    max-height: 240px;
    overflow-y: auto;
    padding: 0;
    border-radius: 8px;
}

.Setup_dropdown-list__NNhsW li {
    list-style: none;
    width: 100%;
}

.Setup_dropdown-item__20wI_ {
    background-color: white;
    padding: 8px;
    cursor: pointer;
}

.Setup_dropdown-item__20wI_:hover {
    background-color: silver;
}

/** Deck Builder */

.DeckBuilder_screen__I-oy0 {
    height: calc(100vh);
    max-width: 1440px;
    display: -webkit-flex;
    display: flex;
    margin: auto;
    border-color: #235c8d;
    border-style: solid;
    border-width: 0px 8px 0px 8px;
    background-color: #83A2BB;
    font-family: 'Helvetica Neue' 'PT Sans', Roboto, sans-serif;
    font-weight: bold;
}

.DeckBuilder_left__2BvvT {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 250px;
    -webkit-align-items: center;
            align-items: center;
}

.DeckBuilder_center___xw72 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 8px 8px 0px 8px;
}

.DeckBuilder_right__motvS {
    width: 250px;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.DeckBuilder_deck__3sJqg {
    text-align: center;
    padding: 8px;
    margin: 8px;
    background-color: #235c8d;
    border-radius: 8px;
    font-size: large;
    color: #E3EEF4;
    position: relative;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.DeckBuilder_deck-card-row__33NV9 {
    padding: 8px;
    margin: 4px 16px;
    background-color: #E3EEF4;
    border-radius: 8px;
    color: #173C5C;
    font-size: small;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
}

.DeckBuilder_deck-card-row-empty__26V-P {
    color: #B2BECC;
    background-color: #B2BECC;
    cursor: default;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.DeckBuilder_card-list__iAmAD {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
    grid-auto-rows: minmax(108px, auto);
    grid-gap: 10px;
    gap: 10px;
    overflow: auto;
    padding: 1em;
}

.DeckBuilder_card__199JZ {
    width: 108px;
    height: 108px;
}

.DeckBuilder_card-list__iAmAD::-webkit-scrollbar {
    width: 20px;
}

.DeckBuilder_card-list__iAmAD::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.671);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.DeckBuilder_card-list__iAmAD::-webkit-scrollbar-track {
    background-color: transparent;
}

.DeckBuilder_header__2O3P1 {
    background-color: #235c8d;
    padding: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    margin-top: 8px;
}

.DeckBuilder_title__heFa1 {
    padding: 8px;
    font-size: large;
    color: white;
}

.DeckBuilder_filter__2Rw3L {
    padding: 8px;
}

.DeckBuilder_search__wLRRv {
    padding: 8px;
    border: 0;
    border-radius: 4px;
    size: 140;
}

.DeckBuilder_search__wLRRv:focus {
    outline: none;
}

.DeckBuilder_tv__2eSgX {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    padding: 8px;
    padding-top: 32px;
    margin: 120px 8px 8px 8px;
    height: 360px;
    border-radius: 8px;
    border: 8px solid #D1E7F3;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Helvetica Neue' 'PT Sans', Roboto, sans-serif;
    font-weight: bold;
    background: rgb(0, 58, 113);
    background: linear-gradient(0deg, rgba(0, 58, 113, 1) 0%, rgba(0, 108, 170, 1) 100%);
}

.DeckBuilder_card-types__1CGTR {
    display: 'flex';
    -webkit-justify-content: 'center';
            justify-content: 'center';
    padding: 8px;
}

.DeckBuilder_card-types__1CGTR>img {
    width: 40px;
}


/* .search::-webkit-search-cancel-button {
    -webkit-appearance: none;
} */
.Game_game__2Tnmq {
    height: 100vh;
    /* height: -webkit-fill-available; */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    /* overflow: hidden; */
}

.Game_game-svg__1aUdR {}

svg {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

svg text::selection {
    background: none;
}

.Game_button__2qHYQ:hover {
    fill-opacity: 100%;
}

.Game_shadow__2Amsc {
    -webkit-filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.6));
            filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.6));
}
