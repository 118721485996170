/** Home */

.wrapper {
    text-align: center;
    color: white;
}

.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button {
    min-width: 256px;
    padding: 8px;
    margin: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.versus {
    display: flex;
    width: 512px;
    margin: 16px;
    background-color: #B2D2E4;
    border-radius: 4px;
    color: #235c8d;
    padding: 16px;
}

.versus-button {
    background-color: #235c8d;
    color: white;
    padding: 8px;
    border-radius: 4px;
    min-width: 100px;
}

.versus-link {
    flex: 1;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #235c8d;
    color: white;
    padding: 8px;
    align-self: stretch;
}