/** Deck Builder */

.screen {
    height: calc(100vh);
    max-width: 1440px;
    display: flex;
    margin: auto;
    border-color: #235c8d;
    border-style: solid;
    border-width: 0px 8px 0px 8px;
    background-color: #83A2BB;
    font-family: 'Helvetica Neue' 'PT Sans', Roboto, sans-serif;
    font-weight: bold;
}

.left {
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
}

.center {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 8px 8px 0px 8px;
}

.right {
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.deck {
    text-align: center;
    padding: 8px;
    margin: 8px;
    background-color: #235c8d;
    border-radius: 8px;
    font-size: large;
    color: #E3EEF4;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deck-card-row {
    padding: 8px;
    margin: 4px 16px;
    background-color: #E3EEF4;
    border-radius: 8px;
    color: #173C5C;
    font-size: small;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.deck-card-row-empty {
    color: #B2BECC;
    background-color: #B2BECC;
    cursor: default;
    user-select: none;
}

.card-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(108px, 1fr));
    grid-auto-rows: minmax(108px, auto);
    gap: 10px;
    overflow: auto;
    padding: 1em;
}

.card {
    width: 108px;
    height: 108px;
}

.card-list::-webkit-scrollbar {
    width: 20px;
}

.card-list::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.671);
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

.card-list::-webkit-scrollbar-track {
    background-color: transparent;
}

.header {
    background-color: #235c8d;
    padding: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 8px;
    margin-top: 8px;
}

.title {
    padding: 8px;
    font-size: large;
    color: white;
}

.filter {
    padding: 8px;
}

.search {
    padding: 8px;
    border: 0;
    border-radius: 4px;
    size: 140;
}

.search:focus {
    outline: none;
}

.tv {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 8px;
    padding-top: 32px;
    margin: 120px 8px 8px 8px;
    height: 360px;
    border-radius: 8px;
    border: 8px solid #D1E7F3;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-family: 'Helvetica Neue' 'PT Sans', Roboto, sans-serif;
    font-weight: bold;
    background: rgb(0, 58, 113);
    background: linear-gradient(0deg, rgba(0, 58, 113, 1) 0%, rgba(0, 108, 170, 1) 100%);
}

.card-types {
    display: 'flex';
    justify-content: 'center';
    padding: 8px;
}

.card-types>img {
    width: 40px;
}


/* .search::-webkit-search-cancel-button {
    -webkit-appearance: none;
} */